import { template as template_800756b7cc3d4eacbf8cb6cd524b1b1e } from "@ember/template-compiler";
const FKControlMenuContainer = template_800756b7cc3d4eacbf8cb6cd524b1b1e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
