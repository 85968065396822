import { template as template_f41ee1832e2b4f96817ede9f4735c11a } from "@ember/template-compiler";
const FKLabel = template_f41ee1832e2b4f96817ede9f4735c11a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
